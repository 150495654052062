import { useStaticQuery, graphql } from 'gatsby'

export const useFeatureItems = () => {
  const { content } = useStaticQuery(graphql`
    query {
      content: allContentfulCatalogFeature {
        data: nodes {
          id
          usage
          category {
            title
            image {
              file {
                url
              }
            }
          }
          description
          imageMain {
            title
            localFile {
              childImageSharp {
                gatsbyImageData(
                  layout: CONSTRAINED
                  placeholder: BLURRED
                  width: 600
                  formats: [WEBP]
                )
              }
            }
          }
          photoGallery {
            title
            localFile {
              childImageSharp {
                gatsbyImageData(
                  layout: CONSTRAINED
                  placeholder: BLURRED
                  height: 600
                  formats: [WEBP]
                  quality: 100
                )
              }
            }
            id
          }
          priceRange
        }
      }
    }
  `)

  return content
}
