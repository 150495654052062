import React, { useCallback, useEffect, useState } from 'react'

import { uniqBy } from 'lodash'

import SEO from 'components/shared/seo'
import { useFeatureItems } from 'content-queries/cx/feature-items'
import fireEvent from 'context/tracking/events'
import CatalogProducts, { ProductItem } from 'mx/cx/category/catalog-products'
import FilterLayout from 'mx/cx/filter-layout'
import ProductModal from 'mx/cx/product-modal'

type Filters = {
  product: string[]
  usage: string[]
  priceRange: string[]
}

const initialFilters: Record<keyof Filters, any[]> = {
  product: [],
  usage: [],
  priceRange: [],
}

const FeaturesPage = ({ path }: { path?: string }) => {
  const { data: content } = useFeatureItems()
  const [modalOpen, setModalOpen] = useState(false)

  useEffect(() => {
    fireEvent({
      type: 'page_viewed',
      pageTitle: 'FORM Catalog - Features',
    })
  }, [])

  const applyFilters = useCallback(
    (activeFilters: Filters | null) => (item: ProductItem) => {
      const flags = []
      if (activeFilters?.product)
        flags.push(activeFilters.product.includes(String(item.product?.title)))
      if (activeFilters?.usage)
        flags.push(activeFilters.usage.includes(String(item.usage)))
      if (activeFilters?.priceRange)
        flags.push(activeFilters.priceRange.includes(String(item.priceRange)))

      return flags.every(Boolean)
    },
    [],
  )

  const buildFilterData = useCallback((sortedItems: ProductItem[]): Filters => {
    const filterData = { ...initialFilters }

    sortedItems.forEach((item) => {
      filterData.product.push(item.product)
      filterData.usage.push(item.usage)
      filterData.priceRange.push(item.priceRange)
    })

    return {
      product: uniqBy(filterData.product, (c) => c.title),
      usage: [...new Set(filterData.usage)].sort().filter(Boolean),
      priceRange: [...new Set(filterData.priceRange)].sort().filter(Boolean),
    }
  }, [])

  const handleOpenModal = useCallback(
    (
      item: ProductItem,
      setCurrentItem: React.Dispatch<React.SetStateAction<ProductItem | null>>,
    ) =>
      () => {
        setCurrentItem(item)
        window.location.hash = item.description as string
        setModalOpen(true)
      },
    [],
  )

  const handleCloseModal = useCallback(() => {
    setModalOpen(false)
    const { pathname, search } = window.location
    history.replaceState(undefined, document.title, pathname + search)
  }, [])

  return (
    <FilterLayout<ProductItem, Filters>
      path={path}
      title="Features"
      applyFilters={applyFilters}
      buildFilterData={buildFilterData}
      content={(content as ProductItem[]).map((i) => ({
        ...i,
        product: i.category,
      }))}
      initialFilters={initialFilters}
      openModal={() => setModalOpen(true)}
    >
      {({ currentItem, filteredItems, onChangeFilter, setCurrentItem }) => (
        <>
          <CatalogProducts
            product="features"
            itemFromHash={currentItem}
            items={filteredItems}
            onChangeFilter={onChangeFilter}
            handleItemClick={handleOpenModal}
            setCurrentItem={setCurrentItem}
          />

          <ProductModal
            open={modalOpen}
            handleClose={handleCloseModal}
            photoGallery={currentItem?.photoGallery}
            imageMain={currentItem?.imageMain}
            title={currentItem?.description}
            info={{
              product: currentItem?.product,
              usage: currentItem?.usage,
              priceRange: currentItem?.priceRange,
            }}
          />
        </>
      )}
    </FilterLayout>
  )
}

export default FeaturesPage

export const Head = () => (
  <SEO
    description="Discover our new modern kitchen cabinet features, from pull-out trash cans to cutlery inserts."
    title="Catalog - Features"
  />
)
